<template>
  <b-overlay :show="show" rounded="lg">
    <b-card>
      <b-form @submit.prevent>

      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
export default {
  name: 'AccountingSearch',
  data: () => ({
    show: false,
  }),
}
</script>

<style scoped>

</style>
