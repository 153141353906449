<template>
  <section>
    <AccountingSearch/>
  </section>
</template>

<script>
import AccountingSearch from '@/views/store/accounting/AccountingSearch';

export default {
  name: 'AccountingHome',
  components: { AccountingSearch },
}
</script>

<style scoped>

</style>
